@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese");
@font-face {
    font-family: Kabel;
    src: url(Kabel.otf);
}

@font-face {
    font-family: KabelTitle;
    src: url(KabelTitle.otf);
}

.login-page .logo-content img {
  object-fit: cover;
  max-width: 100%;
}